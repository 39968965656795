
.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker-wrapper>div {
    display: flex;
}

.react-datepicker__time-list-item {
    margin-bottom: 0 !important;
}