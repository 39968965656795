@use "style/base/base.scss" as base;

/* 회원가입 */
.container {
    section{
        margin: 80px auto;
        max-width: 420px;
        width: 100%;
        box-sizing: border-box;

        &>.title{
            text-align: center;
            margin-bottom: 48px;
        }
    }
    

    .area_box{
        padding: 40px;
        box-sizing: border-box;
        width: 100%;

        .logo_title{
            text-align: center;
            margin-bottom: 40px;

            .logo{
                display: inline-block;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(#{base.$images-base}/bp_logo_b.svg);
                width: 130px;
                height: 24px;
            }
        }

        .label_txt{
            margin-top: 8px;
        }

        .button_box{
            margin-top: 32px;
            .button{
                width: 100%;
            }
            .button:not(:first-of-type) {
                margin-top: 12px;
            }
        } 

        .input_box>li:not(:first-of-type) {
            margin-top: 8px;
        }

        .text_box{
            text-align: center;
            word-break: keep-all;
            margin-bottom: 32px;

            .images{
                margin-bottom: 24px;
            }

            .button_box{
                margin-top: 16px;
            }

            &:last-child{
                margin-bottom: 0;
            }
        }



        

    }

    .join_box {
        .input_box>li:not(:first-of-type){
            margin-top: 24px;
        }
        .check_event{
            margin-top: 28px;
            .check_list{
                padding: 8px 12px;
                box-sizing: border-box;
                margin-top: 9px;
            }
            .check_list>li{
                display: flex;
                justify-content: center;

                label{
                    flex: 1 1;
                }

                .button{
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span{
                        display: inline-block;
                        padding: 0 3px;
                    }
                }
            }
            .check_list>li:not(:first-child){
                margin-top: 12px
            }
        }
    }

    .signup {
        .signup_list>li{
            font-size: 0
        }
        .signup_list>li:not(:first-child){
            margin-top: 8px
        }
        .signup_list>li {
            .sns_logo{
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                display: inline-block;
                width: 1.125rem;
                height: 1.125rem;
                vertical-align: middle;
            }
            .sns_logo.bookpal{
                background-image: url(#{base.$images-base}/user/bookpal_simbol.svg);
            }
            .sns_logo.naver{
                background-image: url(#{base.$images-base}/user/naver_simbol.svg);
            }
            .sns_logo.kakao{
                background-image: url(#{base.$images-base}/user/kakao_simbol.svg);
            }
            .sns_logo.google{
                background-image: url(#{base.$images-base}/user/google_simbol.svg);
            }
            .sns_logo.facebook{
                background-image: url(#{base.$images-base}/user/facebook_simbol.svg);
            }
            .sns_logo.apple{
                background-image: url(#{base.$images-base}/user/apple_simbol.svg) !important;
            }
            .txt{
                display: inline-block;
                padding: 0 4px;
                vertical-align: middle;
            }
        }
        .existing_member{
            text-align: center;
            margin-top: 40px
        }
        .existing_member a{
            display: inline-block;
            line-height: 16px;
            padding: 0 3px;
        }
        .existing_member a span{
            display: block;
        }
    }

    .login_box {
        .user_box{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 12px;
            position: relative;

            .find_box{
                flex: 1 1;
                font-size: 0;

                a {
                    display: inline-block;
                    padding: 0 3px;
                    margin-right: 16px;
                    position: relative;
                }

                a::after{
                    display: block;
                    content: '';
                    width: 1px;
                    height: 12px;
                    background-color: rgba(0, 0, 0, 0.07);
                    position: absolute;
                    right: -8px;
                    top : 2px;
                }

                a:last-child{
                    margin: 0;
                }

                a:last-child::after{
                    display: none;
                }
            }

            .toolTip{
                position: absolute;
                left: auto !important;
                right: 0 !important;
                top: 31px !important;
                button{
                    padding: 8px 10px;
                    font-size: 0;
                }
                
                button::before {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 13px;
                    top: -12px;
                    border-top: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-bottom: 6px solid #EC2A38;
                    border-left: 6px solid transparent;
                }
                button.color-btn-02.bg::before{
                    border-bottom: 6px solid #121212;
                }
                button.top_left::before {
                    left: auto;
                    right: 13px;
                }
                button.color-btn-02.bg .clear_btn{
                    margin-left: 4px;
                    width: 1rem;
                    height: 1rem;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    background-image: url(#{base.$images-base}/tooltip_black_clearbtn-LightMode.svg);
                    display: inline-block;
                    vertical-align: top;
                }
            }
        }

        .check_area{
            margin-top: 8px
        }

        .bottom_con{
            margin-top: 88px;
            text-align: center;

            .button_box{
                margin-top: 20px;
            }

            .previous_login button{
                width: 100%;
                padding: 10px 20px;

                .record{
                    margin-top: 4px;
                }
            }

            .title{
                position: relative;
                line-height: 14px;

                span{
                    display: inline-block;
                    padding: 0 10px;
                    position: relative;
                    z-index: 1;
                }

                &::before{
                    display: block;
                    content: '';
                    width: 100%;
                    height: 1px;
                    background: rgba(0, 0, 0, 0.07);
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                }
            }

        }

        .sns_link{
            margin-top: 16px;
            font-size: 0;

            a {
                display: inline-block;
                margin-right: 20px
            }

            a:last-child{
                margin: 0;
            }
        }
    }

    
    .find_box {
        .info_txt {
            text-align: center;
            margin-top: 32px;
            margin-bottom: 20px;
        }
        .spam_txt {
            text-align: center;
            margin-top: 32px;
            word-break: keep-all;
        }
        .input_box>li:not(:first-of-type) {
            margin-top: 8px;
        }
        .input_box>li:not(:first-of-type) .input_txt{
            margin-top: 24px;
        }
    }
    /* 비밀번호 확인 */
    .comfirm_box .find_pw_box{
        text-align: center;
        margin-top: 32px;
    }
    .comfirm_box .find_pw_box .button{
        margin-top: 5px;
        border: 0 none;
        border-bottom: 1px solid #938F8F
    }

    .adultVerify {
        padding: 0px;
        
        .logo_title .logo {
            background-image: url(#{base.$images-base}/user/img_adult_badge_l_light.svg);
            width: 90px;
            height: 90px;
        }
    }
}




/* 로그인 */
#container .login_box .user_box{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    position: relative;
}

// .DarkMode #container .login_box .bottom_con .title::before,
// .DarkMode #container .login_box .user_box .find_box a::after{
//     background-color: rgba(255, 255, 255, 0.10);
// }


@media (max-width: 999px) {

    .container section{
        padding: 0 24px;
    }
    // .container.s_wrap{
    //     margin-bottom: 80px;
    // }

    .container .area_box{
        padding: 0;
        border: 0 none
    }
    .container section{
        margin: 56px auto;
    }
    .container section>.title{
        display: none;
    }
    .container .login_box .bottom_con{
        margin-top: 58px
    }
    .container .complete .menu_list{
        padding-top: 24px;
    }
    .container .complete .menu_list>li:not(:first-child){
        margin-top: 8px;
    }

    .container .login_box .user_box .find_box a{
        margin-right: 8px
    }
    .container .login_box .user_box .find_box a::after{
        right: -4px
    }
    .container .join_box .button_box{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    .container .join_box .button_box button{
        border-radius: 0;
    }

}
