.coverModal .modal-dialog {
    max-width: 100%;
    max-height: 100vh;
    overflow: hidden;
    text-align: center;
    position: relative;
    padding: 7%;
    box-sizing: border-box;
  }
  .coverModal .modal-dialog .area_box {
    width: auto;
    height: 100%;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    padding: 0;
    background: none;
    justify-content: center;
  }
  .coverModal .modal-dialog .area_box .closeBtn {
    text-align: right;
    margin-bottom: 12px;
  }
  .coverModal .modal-dialog .area_box .closeBtn button {
    border: 0 none;
  }
  .coverModal .modal-dialog .area_box .closeBtn img {
    width: 24px;
  }
  .coverModal .modal-dialog .area_box img {
    width: auto;
    max-width: 100%;
    max-height: calc(100% - 36px);
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
  }