@use "style/base/base.scss" as base;

// .navMenu{
//     top: 0;
//     display: none;
// }
.navMenu {
    padding: 8px 0;
    position: sticky;
    top: 64px;
    left: 0;
    box-sizing: border-box;
    width:100%;
    z-index: 10;
    min-width: 320px;

    .title_menu{
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;

        .btns{
            width: auto;
            height: 48px;
            display: flex;
            z-index: 10;
            // min-width: 48px;
            // display: none;

            button{
                width: auto;
                height: 100%;
                box-sizing: border-box;
                vertical-align: top;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 24px;

                &.prev_btn{
                    background-image: url(#{base.$images-base}/icon_btn_prev-light.svg);
                }
                &.home_btn{
                    background-image: url(#{base.$images-base}/icon_btn_home-light.svg);
                }
                &.finished_btn{
                    background-image: url(#{base.$images-base}/icon_btn_finished-light.svg);
                    background-size: 48px;
                }
                &.finished_not_btn{
                    background-image: url(#{base.$images-base}/icon_btn_finished_not-light.svg);
                    background-size: 48px;
                }
                &.close_btn{
                    background-image: url(#{base.$images-base}/close_btn_black.svg);
                }
                &.favorite_btn{
                    background-image: url(#{base.$images-base}/contents/icon_favoriteBtn_black.svg);
                    // background-image: url(#{base.$images-base}/contents/icon_favoriteBtn.svg)
                    &.click{
                        background-image: url(#{base.$images-base}/contents/icon_favoriteBtn_click.svg);
                    }
                    
                }
                &.alarm_btn{
                    background-image: url(#{base.$images-base}/contents/icon_alarm_black.svg);
                    // background-image: url(#{base.$images-base}/contents/icon_alarm.svg)
                    &.click{
                        background-image: url(#{base.$images-base}/contents/icon_alarm_click.svg);
                    }
                }
                &.detail_btn{
                    background-image: url(#{base.$images-base}/contents/icon_detail_black.svg)
                    // background-image: url(#{base.$images-base}/contents/icon_detail.svg)
                }
                &.faq_search_btn{
                    background-image: url(#{base.$images-base}/help/icon_btn_faqBtn-LightMode.svg);
                }
                &.content_home_btn {
                    background-image: url(#{base.$images-base}/icon_btn_contents-light.svg);
                }
            }
        }

        .title-04{
            font-size: 24px;
            line-height: 48px !important;
            flex: 1 1 0;
        }

        .right_btns {
            button {
                margin-left: auto;
                min-width: 48px;
            }
        }
    }

    .wrapper{
        overflow: hidden;
    }

    .swiper-slide{
        width: auto;

        a {
            display: block;
            padding: 12px 8px;
            font-size: 16px !important;
        }
        span {
            display: block;
            padding-bottom: 4px;
            position: relative;
        }
    }

    .swiper-slide.on span{
        border-bottom-width: 2px;
        color: #121212;
        font-weight: bold;
    }

    .swiper-slide:hover span{
        color: #121212;
    }
}

.navMenu.contents_header {
    .title_menu{
        .btns{
            
            button{
                
                &.prev_btn{
                    background-image: url(#{base.$images-base}/icon_btn_prev-dark.svg);
                }
                &.home_btn{
                    background-image: url(#{base.$images-base}/icon_btn_home-dark.svg);
                }
                &.finished_btn{
                    background-image: url(#{base.$images-base}/icon_btn_finished-dark.svg);
                    background-size: 48px;
                }
                &.finished_not_btn{
                    background-image: url(#{base.$images-base}/icon_btn_finished_not-light.svg);
                    background-size: 48px;
                }
                &.close_btn{
                    background-image: url(#{base.$images-base}/close_btn_black.svg);
                }
                &.favorite_btn{
                    background-image: url(#{base.$images-base}/contents/icon_favoriteBtn.svg);
                    // background-image: url(#{base.$images-base}/contents/icon_favoriteBtn.svg)
                    &.click{
                        background-image: url(#{base.$images-base}/contents/icon_favoriteBtn_click.svg);
                    }
                }
                &.alarm_btn{
                    background-image: url(#{base.$images-base}/contents/icon_alarm.svg);
                    // background-image: url(#{base.$images-base}/contents/icon_alarm.svg)
                    &.click{
                        background-image: url(#{base.$images-base}/contents/icon_alarm_click.svg);
                    }
                }
                &.detail_btn{
                    background-image: url(#{base.$images-base}/contents/icon_detail.svg)
                    // background-image: url(#{base.$images-base}/contents/icon_detail.svg)
                }
                &.faq_search_btn{
                    background-image: url(#{base.$images-base}/help/icon_btn_faqBtn-LightMode.svg);
                }
            }
        }
    }
}

.adult_btn input {
    appearance: none;
    position: relative;
    width: 38px;
    height: 16px;
    border-radius: 999px;
    background-color: rgba(18, 18, 18, .2 );
    display: inline-block;
    vertical-align: middle;
    margin: 4px;
    cursor: pointer;
}
.adult_btn input::before {
    content: "19";
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, .07);
    background-color: #fff;
    box-shadow: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.12));
    transition: left 250ms linear;
    font-size: 14px;
    vertical-align: top;
    position: absolute;
    top: -4px;
    left: -4px;
    color: #a9a5a5;
}
  
.adult_btn input:checked {
    background-color: rgba(236,42,56,.2);
}
.adult_btn input:checked::before {
    background-color: #EC2A38;
    left: 19px;
    color: #fff;
}

// .DarkMode .navMenu .title_menu .btns button.prev_btn{
//     background-image: url(#{base.$images-base}/icon_btn_prev-dark.svg);
// }
// .DarkMode .navMenu .title_menu .btns button.home_btn{
//     background-image: url(#{base.$images-base}/icon_btn_home-dark.svg);
// }
// .DarkMode .navMenu .title_menu .btns button.finished_btn{
//     background-image: url(#{base.$images-base}/icon_btn_finished-dark.svg);
// }
// .DarkMode .navMenu .title_menu .btns button.finished_not_btn{
//     background-image: url(#{base.$images-base}/icon_btn_finished_not-dark.svg);
// }
// .DarkMode .navMenu .title_menu .btns button.close_btn{
//     background-image: url(#{base.$images-base}/close_btn_white.svg);
// }

// .DarkMode .navMenu .swiper-slide.on span,
// .DarkMode .navMenu .swiper-slide:hover span{
//     color: #fff
// }

@media (max-width: 999px) {
    .navMenu {
        top: 48px;
        padding: 0;

        .swiper-container{
            padding-left: 4px;
        }

        .title_menu {
            
            .title-04{
                text-align: center;
                font-size: 18px;
                position: absolute;
                width:100%;
            }
            .btns{
                display: flex;
                // width:48px;
                // max-width: 48px;
                min-width: 48px;
                max-width: 144px;
                button {
                    width:48px;
                    min-width: 48px;
                }
            }
        }
    }

    .adult_btn label {
        padding: 0 3px;
    }
    .adult_btn input::before{
        width: 20px;
        height: 20px;
        font-size: 10px;
        top: -3px;
        left: -3px;
    }
    .adult_btn input:checked::before{
        left: 14px;
    }
    .adult_btn input{
        width: 31px;
        height: 14px;
    }

    

}
