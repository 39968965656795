@use "style/base/base.scss" as base;

.toolTip{
    left: auto !important;
    right: 43px;
    display: none;
}
.section .tit_con{
    margin-top: 12px
}
.tit_con {
    .right .button{
        padding-right: 3px;
        border: 0 none;
        span{
            width: 1rem;
            height: 1rem;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 3px;
            background-image: url(#{base.$images-base}/details/icon_info_circle_LightMode.svg);
        }
    }
}

@media (max-width: 999px) {

    .toolTip{
        right: 14px
    }
    .container .section:first-child{
        margin-top: 0;
    }
}

@media (max-width: 540px) {
    .solo_section .top_con .cover_img{
        width: 128px;
        height: 128px;
    }
    .gift_banner .num_gift{
        right: 90px;
        top: 22px;
    }

}