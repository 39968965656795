@use 'style/common/guid.scss' as *;
@use "style/base/base.scss" as base;


html[data-theme="light"] {
    --home-pc-btn-image-w: url(#{base.$images-base}/ic_menu_home_w.svg);
    --home-pc-btn-image-b: url(#{base.$images-base}/ic_menu_home_b.svg);
    --home-pc-btn-image-active: url(#{base.$images-base}/ic_menu_home_click.svg);
    --home-mobile-btn-image-light: url(#{base.$images-base}/ic_menu_home-LightMode.svg);
    --novel-pc-btn-image-w: url(#{base.$images-base}/ic_menu_novel_w.svg);
    --novel-pc-btn-image-b: url(#{base.$images-base}/ic_menu_novel_b.svg);
    --novel-pc-btn-image-active: url(#{base.$images-base}/ic_menu_novel_click.svg);
    --novel-mobile-btn-image-light: url(#{base.$images-base}/ic_menu_novel-LightMode.svg);
    --toon-pc-btn-image-w: url(#{base.$images-base}/ic_menu_webtoon_w.svg);
    --toon-pc-btn-image-b: url(#{base.$images-base}/ic_menu_webtoon_b.svg);
    --toon-pc-btn-image-active: url(#{base.$images-base}/ic_menu_webtoon_click.svg);
    --toon-mobile-btn-image-light: url(#{base.$images-base}/ic_menu_webtoon-LightMode.svg);
    --gift-pc-btn-image-w: url(#{base.$images-base}/ic_menu_gift_w.svg);
    --gift-pc-btn-image-b: url(#{base.$images-base}/ic_menu_gift_b.svg);
    --gift-pc-btn-image-active: url(#{base.$images-base}/ic_menu_gift_click.svg);
    --gift-mobile-btn-image-light: url(#{base.$images-base}/ic_menu_gift-LightMode.svg);
    --storage-pc-btn-image-w: url(#{base.$images-base}/ic_menu_storage_w.svg);
    --storage-pc-btn-image-b: url(#{base.$images-base}/ic_menu_storage_b.svg);
    --storage-pc-btn-image-active: url(#{base.$images-base}/ic_menu_storage_click.svg);
    --storage-mobile-btn-image-light: url(#{base.$images-base}/ic_menu_storage-LightMode.svg);
    --pc-btn-color-active: #ec2a38;
    --pc-btn-color-w: #fff;
    --pc-btn-color-b: #121212;
    --mobilc-btn-color: #938F8F;
}

html[data-theme="dark"] {
    --home-pc-btn-image-w: url(#{base.$images-base}/ic_menu_home_w.svg);
    --home-pc-btn-image-b: url(#{base.$images-base}/ic_menu_home_w.svg);
    --home-pc-btn-image-active: url(#{base.$images-base}/ic_menu_home_click-DarkMode.svg);
    --home-mobile-btn-image-light: url(#{base.$images-base}/ic_menu_home-DarkMode.svg);
    --novel-pc-btn-image-w: url(#{base.$images-base}/ic_menu_novel_w.svg);
    --novel-pc-btn-image-b: url(#{base.$images-base}/ic_menu_novel_w.svg);
    --novel-pc-btn-image-active: url(#{base.$images-base}/ic_menu_novel_click-DarkMode.svg);
    --novel-mobile-btn-image-light: url(#{base.$images-base}/ic_menu_novel-DarkMode.svg);
    --toon-pc-btn-image-w: url(#{base.$images-base}/ic_menu_webtoon_w.svg);
    --toon-pc-btn-image-b: url(#{base.$images-base}/ic_menu_webtoon_w.svg);
    --toon-pc-btn-image-active: url(#{base.$images-base}/ic_menu_webtoon_click-DarkMode.svg);
    --toon-mobile-btn-image-light: url(#{base.$images-base}/ic_menu_webtoon-DarkMode.svg);
    --gift-pc-btn-image-w: url(#{base.$images-base}/ic_menu_gift_w.svg);
    --gift-pc-btn-image-b: url(#{base.$images-base}/ic_menu_gift_w.svg);
    --gift-pc-btn-image-active: url(#{base.$images-base}/ic_menu_gift_click-DarkMode.svg);
    --gift-mobile-btn-image-light: url(#{base.$images-base}/ic_menu_gift-DarkMode.svg);
    --storage-pc-btn-image-w: url(#{base.$images-base}/ic_menu_storage_w.svg);
    --storage-pc-btn-image-b: url(#{base.$images-base}/ic_menu_storage_w.svg);
    --storage-pc-btn-image-active: url(#{base.$images-base}/ic_menu_storage_click-DarkMode.svg);
    --storage-mobile-btn-image-light: url(#{base.$images-base}/ic_menu_storage-DarkMode.svg);
    --pc-btn-color-active: #ff3746;
    --pc-btn-color-w: #fff;
    --pc-btn-color-b: #fff;
    --mobilc-btn-color: #817B7B;
}

.nav-btn {
    display: inline-block;
    margin-right: 24px;
    font-size: 0;
    vertical-align: top;
    height: 100%;
    position: relative;
}

.nav-a {
    display: block;
    width: 100%;
    height: 100%;
    background-position: left center;
    background-size: 24px;
    background-repeat: no-repeat;
    padding-left: 28px;
    box-sizing: border-box;
    transition: all .2s;
}

.nav-p {
    display: inline-block;
    height: 100%;
    line-height: 48px;
    vertical-align: top;
    box-sizing: border-box;
    padding-right: 4px;
    transition: all .2s;
}

.nav-p-pc-icon {
    padding-right: 0px;
}

.nav-p-icon {
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-size: contain;
    margin-bottom: 2px;
    position: relative
}

.nav-li {
    display: inline-block;
    margin-right: 24px;
    font-size: 0;
    vertical-align: top;
    height: 100%;
    position: relative;
}
.nav-li-mobile {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    text-align: center;
}
.nav-btn-pc {
    @extend .btn-04, .white-always, .txt;
}
.nav-btn-mobile {
    @extend .color-txt-05, .btn-02, .txt;
}




.home {
    
    &.pc {
        @extend .nav-btn;
        @extend .nav-li;
        p { @extend .nav-p; }
        p.icon { @extend .nav-p-pc-icon; }
        a {
            @extend .nav-a;
            & {
                background-image: var(--home-pc-btn-image-b);
                .nav-btn-pc {
                    color: var(--pc-btn-color-b);
                }
            }
            &.main {
                background-image: var(--home-pc-btn-image-w);
                .nav-btn-pc {
                    color: var(--pc-btn-color-w);
                }
            }
            &:hover {
                background-image: var(--home-pc-btn-image-active);
                .nav-btn-pc {
                    color: var(--pc-btn-color-active);
                }
            }
            &.active {
                background-image: var(--home-pc-btn-image-active);
                .nav-btn-pc {
                    color: var(--pc-btn-color-active);
                }
            }
        }
    }
    &.mobile {
        @extend .nav-li-mobile;
        a {
            @extend .nav-a;
            p.icon { 
                @extend .nav-p-icon;
                background-image: var(--home-mobile-btn-image-light);
            }
            p {
                color: var(--mobilc-btn-color);
            }
            &.active { 
                p.icon {
                    @extend .nav-p-icon;
                    background-image: var(--home-pc-btn-image-active);
                }
                p {
                    color: var(--pc-btn-color-active);
                }
            }
        }
    }
}

.novel {
   

    &.pc {
        @extend .nav-btn;
        @extend .nav-li;
        p { @extend .nav-p; }
        p.icon { @extend .nav-p-pc-icon; }
        a {
            @extend .nav-a;
            & {
                background-image: var(--novel-pc-btn-image-b);
                .nav-btn-pc {
                    color: var(--pc-btn-color-b);
                }
            }
            &.main {
                background-image: var(--novel-pc-btn-image-w);
                .nav-btn-pc {
                    color: var(--pc-btn-color-w);
                }
            }
            &:hover {
                background-image: var(--novel-pc-btn-image-active);
                .nav-btn-pc {
                    color: var(--pc-btn-color-active);
                }
            }
            &.active {
                background-image: var(--novel-pc-btn-image-active);
                .nav-btn-pc {
                    color: var(--pc-btn-color-active);
                }
            }
        }
    }
    &.mobile {
        @extend .nav-li-mobile;
        a {
            @extend .nav-a;
            p.icon { 
                @extend .nav-p-icon;
                background-image: var(--novel-mobile-btn-image-light);
            }
            p {
                color: var(--mobilc-btn-color);
            }
            &.active { 
                p.icon {
                    @extend .nav-p-icon;
                    background-image: var(--novel-pc-btn-image-active);
                }
                p {
                    color: var(--pc-btn-color-active);
                }
            }
        }
    }
}

.toon {
    

    &.pc {
        @extend .nav-btn;
        @extend .nav-li;
        p { @extend .nav-p; }
        p.icon { @extend .nav-p-pc-icon; }
        a {
            @extend .nav-a;
            & {
                background-image: var(--toon-pc-btn-image-b);
                .nav-btn-pc {
                    color: var(--pc-btn-color-b);
                }
            }
            &.main {
                background-image: var(--toon-pc-btn-image-w);
                .nav-btn-pc {
                    color: var(--pc-btn-color-w);
                }
            }
            &:hover {
                background-image: var(--toon-pc-btn-image-active);
                .nav-btn-pc {
                    color: var(--pc-btn-color-active);
                }
            }
            &.active {
                background-image: var(--toon-pc-btn-image-active);
                .nav-btn-pc {
                    color: var(--pc-btn-color-active);
                }
            }
        }
    }
    &.mobile {
        @extend .nav-li-mobile;
        a {
            @extend .nav-a;
            p.icon { 
                @extend .nav-p-icon;
                background-image: var(--toon-mobile-btn-image-light);
            }
            p {
                color: var(--mobilc-btn-color);
            }
            &.active { 
                p.icon {
                    @extend .nav-p-icon;
                    background-image: var(--toon-pc-btn-image-active);
                }
                p {
                    color: var(--pc-btn-color-active);
                }
            }
        }
    }
}

.gift {
    &.pc {
        @extend .nav-btn;
        @extend .nav-li;
        p { @extend .nav-p; }
        p.icon { @extend .nav-p-pc-icon; }
        a {
            @extend .nav-a;
            & {
                background-image: var(--gift-pc-btn-image-b);
                .nav-btn-pc {
                    color: var(--pc-btn-color-b);
                }
            }
            &.main {
                background-image: var(--gift-pc-btn-image-w);
                .nav-btn-pc {
                    color: var(--pc-btn-color-w);
                }
            }
            &:hover {
                background-image: var(--gift-pc-btn-image-active);
                .nav-btn-pc {
                    color: var(--pc-btn-color-active);
                }
            }
            &.active {
                background-image: var(--gift-pc-btn-image-active);
                .nav-btn-pc {
                    color: var(--pc-btn-color-active);
                }
            }
        }
    }
    &.mobile {
        @extend .nav-li-mobile;
        a {
            @extend .nav-a;
            p.icon { 
                @extend .nav-p-icon;
                background-image: var(--gift-mobile-btn-image-light);
            }
            p {
                color: var(--mobilc-btn-color);
            }
            &.active { 
                p.icon {
                    @extend .nav-p-icon;
                    background-image: var(--gift-pc-btn-image-active);
                }
                p {
                    color: var(--pc-btn-color-active);
                }
            }
        }
    }
}

.storage {
    

    &.pc {
        @extend .nav-btn;
        @extend .nav-li;
        p { @extend .nav-p; }
        p.icon { @extend .nav-p-pc-icon; }
        a {
            @extend .nav-a;
            & {
                background-image: var(--storage-pc-btn-image-b);
                .nav-btn-pc {
                    color: var(--pc-btn-color-b);
                }
            }
            &.main {
                background-image: var(--storage-pc-btn-image-w);
                .nav-btn-pc {
                    color: var(--pc-btn-color-w);
                }
            }
            &:hover {
                background-image: var(--storage-pc-btn-image-active);
                .nav-btn-pc {
                    color: var(--pc-btn-color-active);
                }
            }
            &.active {
                background-image: var(--storage-pc-btn-image-active);
                .nav-btn-pc {
                    color: var(--pc-btn-color-active);
                }
            }
        }
    }
    &.mobile {
        @extend .nav-li-mobile;
        a {
            @extend .nav-a;
            p.icon { 
                @extend .nav-p-icon;
                background-image: var(--storage-mobile-btn-image-light);
            }
            p {
                color: var(--mobilc-btn-color);
            }
            &.active { 
                p.icon {
                    @extend .nav-p-icon;
                    background-image: var(--storage-pc-btn-image-active);
                }
                p {
                    color: var(--pc-btn-color-active);
                }
            }
        }
    }
}