@use "style/base/base.scss" as base;

.list .no_data{
    align-items: flex-start;
}
.list>div{
    margin-top: 36px;
}
.list .tit_con{
    margin-bottom: 12px
}
.list .keyword_box .keyword{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 12px 8px;
}
.list .keyword_box .keyword>li{
    display: flex;
    align-items: center;
    justify-content: center;
} 
.list .keyword_box .keyword>li a{
    padding: 10px 0 10px 14px
}
.list .keyword_box .keyword>li button{
    padding: 10px 14px 10px 0;
    border: 0 none;
}
.list .keyword_box .keyword>li button span{
    width: 1.25rem;
    height: 1.25rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(#{base.$images-base}/heart_mark_outlined.svg);
    display: block;
}
.list .keyword_box .keyword>li button.click span{
    background-image: url(#{base.$images-base}/heart_mark_filled.svg);
}
.bookmark_box .keyword_box .keyword>li button span{
    background-image: url(#{base.$images-base}/heart_mark_red_click.svg);
}

@media (max-width: 999px) {

    .container .section{
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 40px;
    }
    .section .tit_con,
    .section .top_con, 
    .section .list{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .list .keyword_box .keyword{
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media (max-width: 540px) {

    .list .keyword_box .keyword{
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 8px
    }
    .section .banner_big.list{
        margin-top: 0
    }
}