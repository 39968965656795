@use "style/base/base.scss" as base;


.gift_banner {
    .button{
        font-size: 0;
        margin-top: 16px;
        span{
            vertical-align: middle;
        }
        .link_icon{
            background-repeat: no-repeat;
            background-position: right center;
            background-image: url(#{base.$images-base}/gift/gift_link_red_icon.svg);
            background-size: contain;
            width: 1.125rem;
            height: 1.125rem;
        }
    }
    .num_gift{
        position: absolute;
        top: 42px;
        right: 130px;
        z-index: 1;
        padding: 8px 10px;
        &::before{
            display: block;
            content: '';
            position: absolute;
            right: 20px;
            bottom: -12px;
            border-bottom: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #121212;
            border-left: 6px solid transparent;
        }
        p{
            min-width: 33px;
            text-align: center;
        }
    }
    .right{
        width: 160px;
    }
}
.temp_list{
    margin-top: 0;
    &.template_column .list li.gift_list .button_box button.gift_btn span::after{
        background-image: url(#{base.$images-base}/gift/icon_button_gift_white.svg);
    }
    
}
.template .info_txt .date{
    margin-top: 16px;
}

@media (max-width: 999px) {

    .gift_banner {
        .num_gift{
            right: 90px; 
            top: 22px;
        }
        .button{
            margin-top: 8px;
        }
        .right{
            width: 128px;
        }
    }
}