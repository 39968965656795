.cover_img {
    width: 100px;
    height: 150px;
}

@media (max-width: 540px) {
    .cover_img {
        width: 90px;
        height: 135px;
    }

}

