.cover_img {
    width: 150px;
    height: 225px;
    background: #ffffff;
}

.swiper-slide {
    width: 150px !important;
}

@media (max-width: 540px) {
    .cover_img {
        width: 120px;
        height: 180px;
    }

    .swiper-slide {
        width: 120px !important;
    }
}

