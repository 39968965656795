@use "style/base/base.scss" as base;

.menu_list .toggle_list .button::after,
.input_box .text_input.time_box button::after{
    transform: rotate(-90deg);
}
.menu_list .toggle_list .button>p{
    position: relative;
    margin-right: auto;
    padding-right: 4px
}
.containerr .input_area{
    margin-top: 12px
}
.containerr .input_area .area_box ul>li{
    margin-bottom: 32px
}
.containerr .input_box label:not(:first-of-type){
    margin-left: 20px
}
.containerr .input_box .text_input:not(:first-of-type){
    margin-top: 8px
}

.containerr .tab_list{
    margin-bottom: 12px
}
.containerr .menu_list{
    margin: 40px auto
}
.containerr .history_box{
    margin-top: 12px
}
.answer_label>span{
    padding: 0 4px;
    height: 16px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}
.answer_label>span.answer_waiting{
    border-color: #938f8f
}
.history_box .toggle_list .button .answer_label{
    display: flex;
    align-self: baseline;
}
.history_box .toggle_list .button .info_txt{
    flex: 1;
    margin-left: 6px;
}
.history_box .toggle_list .button .info_txt>p:not(:first-of-type){
    margin-top: 6px;
}
.history_box .toggle_list .txt_box .answer_box{
    margin-top: 16px;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
}
.history_box .toggle_list .txt_box .answer_box .title_box{
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.history_box .toggle_list .txt_box .answer_box .title_box .person{
    flex: 1;
}
.history_box .toggle_list .txt_box .answer_box .desc_box{
    padding: 16px;
}
.type_list_btns{
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 24px 0;
    margin-bottom: 12px
}
.type_list_btns .swiper-slide{
    display: inline-block;
    width: auto;
    margin-right: 8px
}
.type_list_btns .swiper-slide:last-child{
    margin-right: 0
}
.type_list_btns .swiper-slide.on a{
    background: #EC2A38;
    border-color: #EC2A38;
}
.DarkMode .type_list_btns .swiper-slide.on a{
    background: #FF3746;
    border-color: #FF3746;
}
.type_list_btns .swiper-slide.on a span{
    color: #fff
}
#navMenu .title_menu .btns button.faq_search_btn{
    background-image: url(#{base.$images-base}/help/icon_btn_faqBtn-LightMode.svg);
}
.DarkMode #navMenu .title_menu .btns button.faq_search_btn{
    background-image: url(#{base.$images-base}/help/icon_btn_faqBtn-DarkMode.svg);
}

/*** faq 검색 ***/
.search_box .s_wrap{
    width: 480px;
    margin: 0 auto
}
.search_box .search_text {
    padding: 0 16px;
    box-sizing: border-box;
    text-align: left;
    display: flex;
    align-items: center;
    height: 50px;
    margin-top: 20px
}
.search_box .search_text::before {
    display: inline-block;
    content: '';
    width: 18px;
    height: 18px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(#{base.$images-base}/search_gray.svg);
    margin-right: 8px;
}
.before .top_con .left .desc{
    margin-top: 16px
}
.search_box .before{
    margin-top: 40px
}
.search_box .before .top_con .right{
    width: 160px;
    height: 160px
}
.search_box .before .type_list{
    text-align: center;
    padding: 18px 12px
}
.search_box .before .type_list ul{
    font-size: 0;
}
.search_box .before .type_list ul li{
    display: inline-block;
    margin: 6px 0;
    margin-right: 8px;
}
.search_box .before .type_list ul li:last-child{
    margin-right: 0
}
.search_box .before .type_list ul li .button span{
    margin: 0 3px
}
.search_box .after .top_con{
    height: 22px;
    margin-bottom: 12px
}

/* 1:1문의 */
.qna_box .button_box{
    text-align: center
}
.qna_box .button_box .button{
    margin-top: 20px
}
.qna_box .button_box .button span{
    margin: 0 4px
}

/*** 약관 ***/
/*** 약관 ***/
.terms_box .area_box .desc_box {
    margin-top: 40px;
  }
  .terms_box .area_box .desc_box .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
  .terms_box .area_box .desc_box .desc {
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 18px;
    color: #231f1f;
  }
  .terms_box .area_box .desc_box .desc h3 {
    margin-top: 64px;
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #121212;
  }
  .terms_box .area_box .desc_box .desc h4,
  .terms_box .area_box .desc_box .desc strong {
    margin-top: 40px;
    margin-bottom: 24px;
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    display: block;
    color: #121212;
  }
  .DarkMode .terms_box .area_box .desc_box .desc {
    color: #eae9e9;
  }
  .DarkMode .terms_box .area_box .desc_box .desc h3,
  .DarkMode .terms_box .area_box .desc_box .desc h4,
  .DarkMode .terms_box .area_box .desc_box .desc strong {
    color: #fff;
  }
  .terms_box .area_box .fixed_button button::after {
    display: inline-block;
    content: "";
    width: 1.375rem;
    height: 1.375rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(#{base.$images-base}/help/icon_downBtn_white.svg);
    vertical-align: middle;
  }
  .terms_box .area_box .fixed_button button span {
    margin: 0 4px;
    vertical-align: middle;
  }


@media (max-width: 999px) {

    .section{
        margin-top: 36px;
        padding-left:12px;
        padding-right: 12px
    }
    .section .tit_con,
    .section .top_con,
    .section .list{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .input_area{
        padding-bottom: 20px;
    }
}

@media (max-width: 540px) {

    .faq_box {
        margin-top: 36px;
    }

    .containerr .input_area .area_box ul>li{
        margin-bottom: 20px
    }
    .search_box .s_wrap{
        max-width: 480px;
        width: 100%
    }
    .search_box .search_text{
        height: 38px;
        margin: 5px auto
    }
    .search_box .before{
        margin: 56px auto
    }
    .search_box .before .top_con .right{
        width: 128px;
        height: 128px;
        margin-left: 0
    }
    .search_box .before .type_list{
        padding-top: 0;
        padding-bottom: 0
    }
    .search_box .before .type_list ul li{
        margin: 2px 0;
        margin-right: 4px
    }
    .search_box .after{
        margin-top: 0
    }
    .search_box .after .top_con{
        margin-bottom: 8px
    }
    .tab_list{
        margin-bottom: 0
    }
    .type_list_btns{
        padding: 0;
        margin-bottom: 20px
    }
    .containerr .history_box{
        margin-top: 0
    }
    .section .toggle_list {
        margin: 20px 0
    }
    .after .toggle_list{
        margin: 0
    }
    .containerr .menu_list{
        margin: 0
    }
    .containerr .input_area{
        margin-top: 20px;
    }

}








.area_box ul>li{
    margin-bottom: 32px;
}
.check_event{
    margin-top: 40px;
    text-align: center
}
.button_box button{
    width: 100%
}
.info_txt .text{
    word-break: keep-all;
    position: relative;
    padding-left: 10px
}
.info_txt .text b{
    font-weight: bold;
}
.info_txt>li .title{
    margin-bottom: 12px
}
.info_txt>li .text:not(:first-of-type){
    margin-top: 8px
}
.info_txt .text::before{
    display: block;
    content: '-';
    position: absolute;
    left: 0;
    top: 0
}
.table_box{
    margin-top: 12px
}
.table_box>p{
    padding: 12px 8px;
    display: flex;
}
.table_box>p span:first-child{
    flex: 1;
    width: 100%
}
.table_box>p span{
    display: inline-block;
    vertical-align: top;
    margin: 0 8px;
    box-sizing: border-box;
    width: 22%
}


@media (max-width: 999px) {
    .subDepth.type_header .swiper-slide {
        margin-right: 8px !important;
    }
    .subDepth.type_header .swiper-slide:last-child {
        margin-right: 0px !important;
    }
}
@media (max-width: 540px) {
    .area_box ul>li{
        margin-bottom: 20px;
    }
    .check_event{
        margin-top: 36px
    }
}