a:focus,
a:hover{
    color: inherit;
    text-decoration: none;
	outline: none;
	outline-offset: 0
}
input:focus, textarea:focus, select:focus, button:focus{
    outline: inherit
}
.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus{
    outline: inherit;
    outline-offset: inherit;
    box-shadow: none
}
.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio]{
	position: static;
	margin: 0
}
.checkbox label, .radio label{
	margin:0;
	padding: 0;
	min-height: auto
}
.container{
	width: auto;
	margin: 0;
	padding: 0
}
.show{
	display: inline-block !important
}
.border {
    border: inherit;
}