@use "style/base/base.scss" as base;


.calendar{
    padding: 0 4.55%;

    .calendarContent{
        background: #fff;
        padding: 56px;
        box-sizing: border-box;
        border-radius: 32px;
    }

    .desc{
        text-align: center;
        padding: 48px 0;
        color: #817B7B;
     }
}

.table_box {
    &>li{
        border-top: 1px solid rgba(0, 0, 0, 0.07);
        padding-top: 24px;
        position: relative;
        font-size: 0;
    }
    &>li:first-child{
        border-top: 0
    }
    &>li>div{
        display: inline-block;
        vertical-align: middle;
    }
    &>li {
        .date_box{
            width: 100px;
            text-align: center;

            .week{
                margin-top: 12px;
                color: #817B7B;
            }
        }
        .text_box{
            width: calc(100% - 100px);
            box-sizing: border-box;
            padding-bottom: 24px;
            display: flex;
            flex-direction: row;
        }
        .info_box{
            flex:  1 1;
            padding:  0 24px;
            box-sizing: border-box;
            &>p:not(:first-child) {
                margin-top: 12px
            }
        }
        .icon_box{
            text-align: center;
            padding: 0 12px;
            .icon{
                font-size: 0;
                img{
                    height: 36px;
                    vertical-align: middle;
                }
                img:not(:first-child) {
                    margin-left: 8px;
                }
            }
            .button{
                margin-top: 16px;
                button{
                    padding: 10px 17px;
                    line-height: 16px;
                }
                .default{
                    background-color: #EC2A38;
                }
                .disabled{
                    background-color: #FBD4D7;
                    color: rgba(0, 0, 0, 0.30);
                    cursor: default;
                }
            }
        }
    } 
}

.event_header {
    .btns{
        padding: 64px 0 120px;
        text-align: center;
        button{
            padding: 28px 42px;
            font-size: 0;
            background-color: #BFBCBC;
            p{
                padding:  0 8px;
                display: inline-block;
                vertical-align: middle;
                font-size: 32px;
                font-weight: 700;
                letter-spacing: -.2px;
            }
            img{
                vertical-align: middle;
            }
        }
    }
}

.typeList {
    .tit_text{
        padding: 60px 0 20px;
    }
    .template_row {
        .list{
            margin-top: -12px;
            padding-bottom: 20px;
            ul{
                top: 0;
            }
        }
    }
    .template_column {
        .list{
            margin-top: 20px;
            li{
                margin: 0;
                padding-bottom: 20px;
                .cover_img{
                    width: 184px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
                a{
                    overflow: hidden;
                }
                .info_txt{
                    margin: 0 24px;
                    .desc{
                        margin-top: 30px;
                    }
                    .keyword{
                        margin-top: 16px;
                        span{
                            margin-right: 12px;
                        }
                    }
                }
            }
        }
    }
    .notice_box{
        padding: 60px;
        margin: 20px 0;
        .title{
            background-image: url(#{base.$images-base}/promotion/LightMode-check_circle.svg);
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 36px;
            background-size: contain;
        }
        .desc{
            margin-top: 12px;
            &>li{
                position: relative;
                margin-bottom: 8px;
                padding-left: 13px;
                word-break: keep-all;
            }
            &>li:last-child{
                margin-bottom: 0;
            }
            &>li::before{
                display: block;
                content: '-';
                font-size: 15px;
                position: absolute;
                left: 0;
                top: 0;
                line-height: 22px;
            }
        }
    }
}

@media (max-width: 999px) {

    .typeList {
        .tit_text{
            padding: 48px 12px 8px;
        }
        .template_row .list, .template_column .list{
            padding-right: 12px;
        }
        .template_column .list li .cover_img{
            width: 23.18%;
        }
        .template_column .list li .info_txt .keyword span{
            margin-right: 8px;
        }
    }
    .container .section:first-child{
        margin-top: 0 !important;
    }
}

@media (max-width: 540px) {    

    .calendar {
        .calendarContent{
            padding: 12px;
            border-radius: 16px;
        }
        .desc{
            padding: 24px 0;
         }
    }
    
    .table_box {
        &>li {
            .date_box{
                width: 60px;
                .week{
                    margin-top: 4px;
                }
            }
            .text_box{
                width: calc(100% - 60px);
                padding-bottom: 18px;
            }
            .info_box{
                padding: 0 12px;
                &>p:not(:first-child) {
                    margin-top: 8px;
                }
                .tag {
                    img {
                        height: 11px;
                    }
                }
            }
            .icon_box {
                
                .icon {
                    img{
                        height: 24px;
                    }
                    img:not(:first-child) {
                        margin-left: 4px;
                    }
                }
                .button{
                    margin-top: 12px;
                    button{
                        padding: 6px 12px;
                    }
                }
            }
        }
    }
    .event_header {
        .btns{
            padding: 48px 0 80px;
            button{
                padding: 20px 24px; 
                p{
                    font-size: 20px;
                } 
                img{
                    height: 28px;
                }
            }
        }
    }

    .typeList {
        .tit_text{
            padding: 28px 12px 0;
        }
        .template_column {
            .list {
                margin-top: 0;
                margin-bottom: 8px;
                li {
                    padding-bottom: 12px;
                    .cover_img{
                        width: 40%;
                    }
                    .info_txt {
                        margin: 0 16px;
                        .desc{
                            margin-top: 24px;
                        }
                    }
                }
            }
        }
        .notice_box{
            margin: 0;
            padding: 36px 24px;
        }
        .template_row .list{
            margin-top: 0;
        }
    }
}