/* .LightMode #header .gnb>li.home a,
.LightMode #Mnavigation>li.home .icon{
    background-image: url(/images/ic_menu_home_click.svg);
}
.DarkMode #header .gnb>li.home a,
.DarkMode #Mnavigation>li.home .icon{
    background-image: url(/images/ic_menu_home_click-DarkMode.svg);
} */

/* visual */
.banner_01{
    padding: 16px 0
}
.keyword .top_con .image img{
    width: 100%
}
.keyword .keyword_type{
    margin-top: 8px;
}
.keyword .keyword_type .swiper-slide{
    width: auto;
}
.keyword .keyword_type .swiper-slide a{
    display: block;
}
.app_banner {
    display: none;
}

#visual{
    position: relative;
    padding-top: 92px;
}
#visual .bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 312px;
    background: #000;
}
#visual .bg .swiper-slide{
    position: relative;
}
#visual .bg .swiper-slide img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: -1;
}
#visual .bg .swiper-slide::after{
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0,0,0,70%);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
}
#visual .banner{
    width: 888px;
    box-sizing: border-box;
    overflow: hidden;
    min-height: 453px;
}
#visual .banner .swiper-slide{
    position: relative;
    width: 330px;
}
#visual .banner .swiper-slide img{
    width: 100%
}
#visual .banner .arrow_btns>div{
    margin-top: -16px;
}
#visual .banner .arrow_btns>div.swiper-button-next{
    right: 0;
}
#visual .banner .arrow_btns>div.swiper-button-prev{
    left: 0;
}
#visual .banner .swiper-pagination{
    position: static;
    margin-top: 16px;
}
/* quickMenu */ 
#quickMenu{
    text-align: center;
    margin-top: 28px;
}
#quickMenu .menu_list{
    font-size: 0
}
#quickMenu .menu_list>li{
    display: inline-block;
    padding: 4px;
    width: 70px;
    height: 58px;
    text-align: center;
    vertical-align: top;
    box-sizing: border-box;

}
#quickMenu .menu_list>li img{
    width: 28px;
    height: 28px;
}
#quickMenu .menu_list>li .s_tit{
    margin-top: 6px;
}