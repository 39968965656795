.quickMenu{
    text-align: center;
    margin-top: 28px;

    .menu_list{
        font-size: 0
    }

    .menu_list>li{
        display: inline-block;
        padding: 4px;
        width: 70px;
        height: 58px;
        text-align: center;
        vertical-align: top;
        box-sizing: border-box;
    
    }

    .menu_list>li img{
        width: 28px;
        height: 28px;
    }

    .menu_list>li .s_tit{
        margin-top: 6px;
    }
}

@media (max-width: 540px) {
    .quickMenu .menu_list>li:nth-child(5),
    .quickMenu .menu_list>li:nth-child(6){
        display: none;
    }
}