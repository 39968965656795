
.react-datepicker-wrapper {
    /* width: 100%; */
    flex: 1 1;
}
.react-datepicker-wrapper>div {
    display: flex;
    align-items: center;
}

.react-datepicker__time-list-item {
    margin-bottom: 0 !important;
}

