.modal-body {
    .keyword_word{
        font-size: 0;
        button {
            margin-right: 8px;
            margin-top: 8px;
        }
    }

    .list li{
        padding: 0 12px;
        margin-bottom: 24px ;

        .title{
            line-height: 32px
        }
    }
}

@media (max-width: 999px) {
    .modal-body {
        .list{
            height: calc(100vh - 94px)
        }
        .list>ul {
            max-height: 100%;
        }
        .list li{
            padding: 0;
        }
    }
}