@use "style/base/base.scss" as base;

.icon_modal_box .modal-content {
    padding-top: 84px !important;
}
.icon_modal_box .modal-content::before {
    display: block;
    content: "";
    width: 120px;
    height: 120px;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    background-image: url(#{base.$images-base}/promotion/misson_modal_img.png);
}