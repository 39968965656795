
@use "style/base/base.scss" as base;


.cover_img::after {
    display: block;
    content: "";
    background-image: url(#{base.$images-base}/solo_section_cover_dark.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: calc(100% - 12px) !important;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 6px !important;
    z-index: 1;
}