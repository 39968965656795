.area_box thead th,
.area_box tbody td{
    padding: 12px 16px 12px 12px;
    vertical-align: middle;
    word-break: keep-all;
    width: 33.33%
}

.area_box table {
    border-color: rgba(0, 0, 0, .07);
    border-top-width: 1px;
    border-style: solid;
}

.area_box thead {
    font-size: .875rem;
    font-weight: bold;
    line-height: 1.125rem;
    color: #281F1F;
    background-color: #f7f5f5;
}

// .area_box thead>tr {
//     font-size: .8125rem;
//     line-height: 1rem;
//     color: #534c4c;
//     border-color: rgba(0, 0, 0, .07);
//     border-bottom-width: 1px;
//     border-top-width: 1px;
//     border-style: solid;
// }

.area_box tbody>tr {
    font-size: .8125rem;
    line-height: 1rem;
    color: #534c4c;
    border-color: rgba(0, 0, 0, .07);
    border-bottom-width: 1px;
    border-top-width: 1px;
    border-style: solid;
}

.area_box p {
    padding-left: 13px;
    position: relative;
    margin-top: 24px;
    word-break: keep-all;
    font-size: .875rem;
    line-height: 1.25rem;
    color: #281F1F;
}

@media (max-width: 999px) {
    .area_box {
        height: 100% !important;
    }
}