.keyword {
    .top_con {
        .image img{
            width: 100%
        }
    }

    .keyword_type{
        margin-top: 8px;

        .swiper-slide{
            width: auto;
        }
        .swiper-slide a{
            display: block;
        }
    }
}