.button {
    width: 100%;
    padding: 11px 15px;
    text-align: left;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: hidden;
    flex: 1;
}

.span {
    flex: 1 1;
    vertical-align: top;
    display: inline-block;
    font-size: 0.875rem;
}