/* visual */
.visual{
    position: relative;
    padding-top: 92px;
    .bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 312px;
        background: #000;

        .swiper-slide{
            position: relative;

            img{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                z-index: -1;
            }
        }

        .swiper-slide::after{
            display: block;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: rgba(0,0,0,70%);
            backdrop-filter: blur(15px);
            -webkit-backdrop-filter: blur(15px);
        }
    }

    .banner{
        width: 888px;
        box-sizing: border-box;
        overflow: hidden;
        min-height: 453px;

        .swiper-slide{
            position: relative;
            width: 330px;
        }

        .swiper-slide img{
            width: 100%
        }

        .arrow_btns>div{
            margin-top: -16px;
        }

        .arrow_btns>div.swiper-button-next{
            right: 0;
        }

        .arrow_btns>div.swiper-button-prev{
            left: 0;
        }

        .swiper-pagination{
            position: static;
            margin-top: 16px;
        }
    }

}

@media (max-width: 999px) {
    .visual{
        padding-top: 48px
    }
    .visual .banner{
        max-width: 808px;
        width: 100%;
        min-height: 390px;
    }
}

@media (max-width: 540px) {

    .visual .banner .swiper-slide{
      width: 300px
    }
}