.arrow_btns {
    &>div {
        top: 50vh;
        transform: none;
        padding: 0.4375rem;
        border-radius: 999px;
    }
}

.fade_element {
    opacity: 1;
}

.fade_element.fadeout {
    // transition: opacity 0s ease-in-out;
    opacity: 0;
}

.fade_element.fadein {
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
}