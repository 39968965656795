@use "style/base/base.scss" as base;

.s_wrap{
    padding: 0 200px;
}

.search_box {
    .area_box{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 480px;
        height: 50px;
        position: relative;
        margin: 0 auto;
        margin-top: 20px;

        .search_text{
            padding: 0 16px 0 20px;
            box-sizing: border-box;
            margin-right: 8px;
            text-align: left;
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
    
            .text_input{
                width: 100%;
    
                .right_btns{
                    margin-right: 0
                }
            }
    
            .text_box{
                padding: 0;
                background: none;
                border: 0 none;
                display: block;
                width: 100%;
    
                .search_text::before{
                    background-image: url(#{base.$images-base}/search/hash_red.svg);
                }
            }
    
            .text_box::placeholder {
                color: #A9A5A5
            }
    
            button{
                display: none;
            }
        }

        .right_button {
            img {
                width: 1.125rem;
            }

            &> button {
                width: 50px;
                height: 50px;
                padding: 0;
            }
            &> button.search_button {
                display: block;
            }
        }
    
        // .search_text::before{
        //     display: inline-block;
        //     content: '';
        //     width: 18px; 
        //     height: 18px;
        //     background-position: center;
        //     background-repeat: no-repeat;
        //     background-image: url(#{base.$images-base}/search_gray.svg);
        //     margin-right: 8px
        // }
    }

    

    .area_box>.button{
        display: flex;
        white-space: nowrap;

        span{
            display: block;
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(#{base.$images-base}/search/hash_gray.svg);
        }
    }

    .keyword_box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 480px;
        height: 50px;
        position: relative;
        margin: 0 auto;
        margin-top: 20px;
        &>.button{
            background-color: #EC2A38;
    
            span{
                background-image: url(#{base.$images-base}/search/hash_white.svg);
            }
    
            .search_text .text_box{
                display: none;
            }
        }

        .right_button {
            img {
                width: 1.125rem;
            }

            &> button {
                width: 50px;
                height: 50px;
                padding: 0;
            }
            &> button.keyword_btn {
                display: block;
            }
        }

        .keyword_btn {
            display: none;
        }

        .search_text {
            padding: 8px 16px;
            box-sizing: border-box;
            margin-right: 8px;
            text-align: left;
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
            .text_input {
                display: none;
            }
            button {
                // padding: 10px 14px;
                // font-size: 0;
                // display: block;
                padding: 10px 14px;
                font-size: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                

                span{
                    padding: 0 4px;
                    max-width: 96px;
                    box-sizing: border-box;
                    // flex: 1 1 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow-wrap: break-word;
                    white-space: normal;
                }

                .txt{
                    margin: 0 4px;
                }

                .close_btn{
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    width: 16px;
                    height: 16px;
                    background-image: url(#{base.$images-base}/close_btn_gray.svg)
                }
            }
            &::before{
                background-image: url(#{base.$images-base}/search/hash_red.svg) !important;
            }
            &::before {
                display: inline-block;
                content: "";
                width: 18px;
                height: 18px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url(#{base.$images-base}/search_gray.svg);
                margin-right: 8px;
                box-sizing: border-box;
              }
        }
        

    }

    

}

.s_wrap {
    padding: 0 200px;
}

.keyword_area {
    display: flex;
    justify-content: center;
}

.keyword_search_btn {
    font-size: 0;
}
.keyword_search_btn span {
    margin: 0 4px;
}
.keyword_search_btn::before,
.keyword_search_btn::after {
    display: inline-block;
    content: "";
    width: 1.125rem;
    height: 1.125rem;
    background-size: contain;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: center;
}
.keyword_search_btn::before {
    background-image: url(#{base.$images-base}/search/hash_red.svg);
}
.keyword_search_btn::after {
    background-image: url(#{base.$images-base}/search/right_btn.svg) !important;
}
.search_box .keyword_box .search_text::before {
    display: inline-block;
    content: "";
    width: 1.125rem;
    height: 1.125rem;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 8px;
    background-image: url(#{base.$images-base}/search/hash_red.svg);
}
.search_box .right_button button.keyword_btn,
.search_box .keyword_box .right_button button.search_button {
  display: none;
}

.recent_search {
    .searh_box{
        margin-top: 8px;

        .swiper-slide{
            width: auto;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;

            a {
                max-width: 96px;
                padding: 16px 0 16px 20px;
                margin-right: 4px;
                flex:  1 1;
                box-sizing: content-box;
            }

            .close_btn{
                padding: 14px 20px;
                padding-left: 0;
                display: flex;
                white-space: nowrap;

                span{
                    display: block;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-image: url(#{base.$images-base}/close_btn_gray.svg);
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}
.popular_search {
    .list{
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-top: 8px;

        li{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-right: 8px;

            a{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 12px;
                box-sizing: border-box;
                width: 100%;
            }

            .text{
                flex: 1 1 0;
                margin: 0 4px
            }
        }

        li:nth-child(2n){
            margin-right: 0;
        }

        .num{
            width: 18px;
            height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            font-weight: 700;
            letter-spacing: -1px;
        }  
    }
}

.toolTip {
    position: absolute;
    left: auto !important;
    right: 0 !important;
    top: 56px !important;
    line-height: 1;
    z-index: 1;
}

.toolTip button {
    padding: 8px 10px;
    font-size: 0;
}

.toolTip button::before {
    display: block;
    content: "";
    position: absolute;
    right: 13px;
    top: -12px;
    border-top: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ec2a38;
    border-left: 6px solid transparent;
}
.toolTip button img {
    margin-left: 4px;
}

// .DarkMode .search_box .search_text .text_box::placeholder {
//     color: #696363
// }





@media (max-width: 999px) {
    .keyword_box {
        margin-top: 3px !important;
    }

    #header .header_inner{
        display: none;
    }
    #navMenu {
        top: 0;
    }
    .modal-body .list{
        height: calc(100vh - 94px)
    }
    .modal-body .list>ul {
        max-height: 100%;
    }
    .modal-body .list li{
        padding: 0;
    }
    .s_wrap{
        padding: 0;
    }
    .container .section:first-child{
        margin-top: 40px
    }
    .search_box .area_box{
        margin-top: 3px;
    }

}

@media (max-width: 540px) {

    .keyword_box {
        margin-top: 3px !important;
    }

    .button.large {
        padding: 0.7375rem !important;
    }

    .search_box {
        .area_box{
            width: 300px;
            height: 40px;
            .right_button > button {
                width: 40px;
                height: 40px;
            }
        }
        .keyword_box{
            width: 300px;
            height: 40px;
            .right_button > button {
                width: 40px;
                height: 40px;
            }
        }
    }
    .search_text {
        padding: 0 12px 0 16px;
        button {
            padding: 6px 10px !important;
        }
    }

    #container .search_box .button{
        padding: 7px;
    }
    .toolTip{
        top: 44px !important;
    }
    .recent_search .searh_box .swiper-slide .close_btn{
        padding: 10px 10px 10px 0
    }
    .recent_search .searh_box .swiper-slide .close_btn span{
        width: 16px;
        height: 16px;
    }
    .recent_search .searh_box .swiper-slide a{
        padding: 10px 0 10px 16px;
    }
    .popular_search .list{
        padding: 0;
    }
    .popular_search .list .num{
        font-size: 10px;
        width: 16px;
        height: 16px;
    }
    .recent_search .searh_box{
        padding-top: 8px;
        padding-bottom: 8px
    }
    .container .section:first-child{
        margin-top: 36px
    }
}