@use "style/base/base.scss" as base;

.section{
    padding: 40px 0;
}
.section .tit_con span{
    margin-right: 4px;
}
.section.bottom_button{
    padding: 48px 0 !important;
    text-align: center;
}
/*** 마이페이지 ***/
.adult_btn label {
    display: block;
    cursor: pointer;
    vertical-align: top;
    width: 46px;
    height: 36px;
    padding: 6px 0;
    z-index: 1;
    position: relative;
}
  
.adult_btn input {
    appearance: none;
    position: relative;
    width: 38px;
    height: 16px;
    border-radius: 999px;
    background-color: rgba(18, 18, 18, .2 );
    display: inline-block;
    vertical-align: middle;
    margin: 4px;
    cursor: pointer;
}
.DarkMode .adult_btn input{
    background-color: rgba(255,255,255,.2);
}
.DarkMode .adult_btn input::before{
    background-color: #121212;
    border-color: rgba(255,255,255,.1);
    color: #817b7b
}
.adult_btn input::before {
    content: "19";
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, .07);
    background-color: #fff;
    box-shadow: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.12));
    transition: left 250ms linear;
    font-size: 14px;
    vertical-align: top;
    position: absolute;
    top: -4px;
    left: -4px;
    color: #a9a5a5
}
  
.adult_btn input:checked {
    background-color: rgba(236,42,56,.2);
}
.DarkMode .adult_btn input:checked {
    background-color: rgba(255, 55, 70, .2);
}

.adult_btn input:checked::before {
    background-color: #EC2A38;
    left: 19px;
    color: #fff;
}
.DarkMode .adult_btn input:checked::before{
    background-color: #ff3746;
}
  
.my_page .my_profile .login_btn{
    text-align: center;
    height: 260px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.my_page .my_profile .login_btn .s_tit{
    margin-bottom: 16px
}
.my_page .my_profile .my_info{
    display: flex;
    align-items: flex-end;
}
.my_page .my_profile .my_info .profile_box{
    position: relative
}
.my_page .my_profile .my_info .profile_box button{
    position: absolute;
    right: 0;
    bottom: 0;
}
.my_page .my_profile .my_info .profile_box button span{
    width: 1rem;
    height: 1rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(#{base.$images-base}/mypage/icon_camera_black.svg);
}
.DarkMode .my_page .my_profile .my_info .profile_box button span{
    background-image: url(#{base.$images-base}/mypage/icon_camera_white.svg);
}
.my_page .my_profile .my_info .profile{
    width: 120px;
    height: 120px;
    overflow: hidden;
}
.my_page .my_profile .my_info .profile img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.my_page .my_profile .my_info .info_txt{
    flex: 1;
    margin-left: 20px
}
.my_page .my_profile .my_info .info_txt .nick_box{
    display: flex;
    align-items: center;
    height: 26px
}
.my_page .my_profile .my_info .info_txt .nick_box .nick_name{
    display: flex;
    align-items: center; 
}
.my_page .my_profile .my_info .info_txt .setting_btn{
    width: 1.25rem;
    height: 1.25rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(#{base.$images-base}/mypage/icon_setting_gray.svg);
    margin-left: 2px
}
.DarkMode .my_page .my_profile .my_info .info_txt .setting_btn{
    background-image: url(#{base.$images-base}/mypage/icon_setting_gray-DarkMode.svg);
}
.my_page .my_profile .my_info .info_txt .rating_box{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px
}
.my_page .my_profile .my_info .info_txt .rating_box .left{
    flex: 1;
}
.my_page .my_profile .my_info .info_txt .rating_box .left span{
    margin-right:3px;
}
.my_page .my_profile .my_info .info_txt .rating_box .benefits_btn button::after{
    display: inline-block;
    content: '';
    width: 1rem;
    height: 1rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(#{base.$images-base}/mypage/icon_info_gray.svg);
    vertical-align: top;
}
.DarkMode .my_page .my_profile .my_info .info_txt .rating_box .benefits_btn button::after{
    background-image: url(#{base.$images-base}/mypage/icon_info_gray-DarkMode.svg);
}
.my_page .my_profile .my_info .info_txt .rating_box .benefits_btn button span{
    margin: 0 3px;
}
.my_page .my_pay .coupon_box{
    display: flex;
    height: 150px;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.my_page .my_pay .coupon_box>div{
    border: 1px solid rgba(210, 167, 126, 0.20)
} 
.my_page .my_pay .coupon_box>.left{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%
}
.my_page .my_pay .coupon_box>.left .icon_coupon_box{
    height: 46px;
    width: 46px;
    margin-right: 24px;

}
.my_page .my_pay .coupon_box>.left .icon_coupon_box span{
    transform: rotate(-30deg);
    display: block;
    background: url(#{base.$images-base}/coupon_color.svg);
    background-size: contain;
    width: 100%;
    height: 100%
}
.my_page .my_pay .coupon_box>.left .text_box{
    width: 140px
}
.my_page .my_pay .coupon_box>.right{
    width: 360px;
    margin-left: 8px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.my_page .my_pay .coupon_box .num_btn{
    margin-top: 8px
}
.my_page .my_pay .coupon_box .num_btn a{
    display: flex;
    justify-content: center;
    align-items: center;
}
.my_page .my_pay .coupon_box .num_btn a::after{
    display: inline-block;
    content: '';
    width: 1.25rem;
    height: 1.25rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(#{base.$images-base}/mypage/icon_numBtn_black.svg);
    margin-left: 4px;
    vertical-align: middle;
}
.DarkMode .my_page .my_pay .coupon_box .num_btn a::after{
    background-image: url(#{base.$images-base}/mypage/icon_numBtn_white.svg);
}
.my_page .my_pay .coupon_box .self_identify .s_tit{
    margin-bottom: 12px;
    word-break: keep-all;
}
.my_page .my_pay .coupon_box .self_identify .s_tit::before{
    display: block;
    content: '';
    width: 18px;
    height: 18px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(#{base.$images-base}/mypage/icon_lockClosed_black.svg);
    margin: 0 auto;
    margin-bottom: 8px
}
.DarkMode .my_page .my_pay .coupon_box .self_identify .s_tit::before{
    background-image: url(#{base.$images-base}/mypage/icon_lockClosed_white.svg);
}
.my_page .my_pay .coupon_box .self_identify .button span{
    margin: 0 3px;
}
.my_page .my_pay .coupon_box .self_identify .button::after{
    display: inline-block;
    content: '';
    width: 1rem;
    height: 1rem;
    vertical-align: top;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(#{base.$images-base}/mypage/icon_identifyBtn_red.svg);
}
.DarkMode .my_page .my_pay .coupon_box .self_identify .button::after{
    background-image: url(#{base.$images-base}/mypage/icon_identifyBtn_red-DarkMode.svg);
}
.my_page .my_pay .coupon_box>.right .after{
    display: flex;
    justify-content: center;
    align-items: center;
}
.my_page .my_pay .coupon_box>.right .after>div{
    margin: 0 12px;
    width: 108px;
}
.my_page .my_pay .button_box{
    margin-top: 16px
}
.my_page .my_pay .button_box button{
    width: 100%
}
.my_page .my_pay .button_box button span{
    margin-right: 4px
}
.my_page .my_pay .button_box button span::before{
    display: inline-block;
    content:'';
    width: 1.125rem;
    height: 1.125rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(#{base.$images-base}/mypage/icon_coupon_white.svg);
    vertical-align: top;
    margin-right: 4px
}
.my_page .menu_list nav>ul{
    font-size: 0;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 8px;
    display: grid;
    position: relative;
}
.my_page .menu_list nav>ul>li>a{
    display: flex;
    padding: 8px 0
}
.my_page .menu_list nav>ul .menu_icon{
    width: 1.25rem;
    height: 1.25rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 8px
}
.my_page .menu_list nav>ul .menu_icon.alarm_icon{
    background-image: url(#{base.$images-base}/ic_menu_alarm_b.svg);
}
.my_page .menu_list nav>ul .menu_icon.event_icon{
    background-image: url(#{base.$images-base}/ic_menu_event_b.svg);
}
.my_page .menu_list nav>ul .menu_icon.chat_icon{
    background-image: url(#{base.$images-base}/ic_menu_chat_b.svg);
}
.my_page .menu_list nav>ul .menu_icon.exclamation_icon{
    background-image: url(#{base.$images-base}/ic_menu_exclamation_b.svg);
}
.my_page .menu_list nav>ul .menu_icon.allMenu_icon{
    background-image: url(#{base.$images-base}/ic_menu_allMenu_b.svg);
}
.my_page .menu_list nav>ul .menu_icon.setting_icon{
    background-image: url(#{base.$images-base}/ic_menu_setting_b.svg);
}
.my_page .menu_list nav>ul .menu_icon.edit_icon{
    background-image: url(#{base.$images-base}/ic_menu_edit_b.svg);
}
.DarkMode .my_page .menu_list nav>ul .menu_icon.alarm_icon{
    background-image: url(#{base.$images-base}/ic_menu_alarm_w.svg);
}
.DarkMode .my_page .menu_list nav>ul .menu_icon.event_icon{
    background-image: url(#{base.$images-base}/ic_menu_event_w.svg);
}
.DarkMode .my_page .menu_list nav>ul .menu_icon.chat_icon{
    background-image: url(#{base.$images-base}/ic_menu_chat_w.svg);
}
.DarkMode .my_page .menu_list nav>ul .menu_icon.exclamation_icon{
    background-image: url(#{base.$images-base}/ic_menu_exclamation_w.svg);
}
.DarkMode .my_page .menu_list nav>ul .menu_icon.allMenu_icon{
    background-image: url(#{base.$images-base}/ic_menu_allMenu_w.svg);
}
.DarkMode .my_page .menu_list nav>ul .menu_icon.setting_icon{
    background-image: url(#{base.$images-base}/ic_menu_setting_w.svg);
}
.DarkMode .my_page .menu_list nav>ul .menu_icon.edit_icon{
    background-image: url(#{base.$images-base}/ic_menu_edit_w.svg);
}
.my_page .menu_list nav>ul .menu_tit{
    position: relative;
    display: inline-block;
    padding: 0 2px
}

/*** 엠블럼 ***/
.myEmblem .emblem_list {
    margin-top: 40px
}
.myEmblem .emblem_list ul{
    font-size: 0;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    grid-gap: 12px;
    display: grid;
}
.myEmblem .emblem_list ul li{
    position: relative
}
.myEmblem .emblem_list ul li.active a::before{
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    border: 3px solid #EC2A38;
    z-index: 1;
    border-radius: 24px;
    background-color: rgba(236,42,56,.1)
}
.DarkMode .myEmblem .emblem_list ul li.active a::before{
    border-color:#ff3746 ;
    background-color: rgba(236,42,56,.2)
}
.myEmblem .emblem_list ul li a{
    overflow: hidden;
    display: block;
}
.myEmblem .emblem_list ul li img{
    width: 100%
}

/*** 등급별 혜택 안내 ***/
.myBenefit .my_benefit .my_grade{
    padding: 28px 0;
    text-align: center;
}
.myBenefit .my_benefit .my_grade p:not(:first-of-type) {
    margin-top: 16px;
}
.myBenefit .my_benefit .my_grade .rating_label span{
    background-position: center;
}
.myBenefit .my_benefit .grade_up{
    padding: 16px;
    margin-top: 12px;
}
.myBenefit .my_benefit .grade_up .left .money{
    margin-top: 4px;
}
.myBenefit .benefit_list .list li{
    padding: 16px 0
}
.myBenefit .benefit_list .list li:first-child{
    padding-top: 12px
}
.myBenefit .benefit_list .list li:last-child{
    padding-bottom: 12px
}
.myBenefit .benefit_list .list .grid_tit{
   display: flex;
   justify-content: center;
   align-items: center;
}
.myBenefit .benefit_list .list .grid_tit .title{
    margin-left: 4px;
    flex: 1
}
.myBenefit .benefit_list .grade_benefit{
    margin-top: 8px
}
.myBenefit .benefit_list .grade_benefit p{
    margin-top: 4px
}
/*** 내정보 수정 ***/
.edit_box .input_box>li{
    margin-bottom: 16px;
}
.edit_box .input_box>li>.flex{
    justify-content: center;
    align-items: center;
}
.edit_box .input_box>li button{
    margin-left: 8px
}
.certifyBtn{
    margin: 0;
    margin-left: 0px !important;
}
.edit_box .input_box>li button span{
    margin: 0 3px
}
.edit_box .input_box>li .sns_logo{
    display: inline-block;
    margin-right: 2px
}
.edit_box .input_box .text_tit{
    width: 96px;
    margin-right: 10px
}
.edit_box .input_box .input_text{
    flex : 1
}
.edit_box .input_box .essential_tit::after{
    display: inline-block;
    content: '*';
    color: #EC2A38;
}
.DarkMode .edit_box .input_box .essential_tit::after{
    color: #ff3746
}
.edit_box .completedBtn{
    width: 100%;
    margin-top: 4px
}
.edit_box .sns_logo img{
    width: 1rem;
}
.edit_box .account_button button{
    width: 100%
}
.edit_box .input_box .text_input:focus-within{
    border-color: #121212
}
.DarkMode .edit_box .input_box .text_input:focus-within{
    border-color: #FFF
}
.edit_box .side_info .button::after{
    display: inline-block;
    content:'';
    width: 1rem;
    height: 1rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(#{base.$images-base}/mypage/icon_btn_red.svg);
    vertical-align: top;
}
.edit_box .side_info .account_button{
    margin-top: 24px
}
.edit_box .side_info .account_button .button::after{
    background-image: url(#{base.$images-base}/mypage/icon_btn_gray.svg);
    width: 1.125rem;
    height: 1.125rem;
}
.edit_box .side_info .account_button button span{
    margin: 0 4px;
}
.DarkMode .edit_box .side_info .account_button button::after{
    background-image: url(#{base.$images-base}/mypage/icon_btn_gray-DarkMode.svg);
}

/*** sns연결정보 ***/
.connection_box .signup_list>li{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding: 8px 12px
}
.connection_box .signup_list .left{
    flex : 1
}
.connection_box .signup_list .sns_login{
    font-size: 0
}
.connection_box .signup_list .sns_logo{
    width: 1.25rem;
    height: 1.25rem;
    vertical-align: top;
    margin-right: 4px
}
.connection_box .signup_list .existing_email{
    margin-top: 8px
}


/*** 회원탈퇴 ***/
.connection_box .section .tit_con{
    margin-bottom: 12px
}
.connection_box .section .check_box{
    padding: 16px;
    box-sizing: border-box;
}
.connection_box .section .check_box .check_event{
    text-align: right;
    margin-top: 20px
}
.connection_box .section .check_list>li{
    margin-top: 12px
}
.connection_box .section>button{
    width: 100%;
    margin-top: 12px
}
.connection_box .section>button:first-child{
    margin: 0
}

/*** 쿠폰내역 ***/
.coupon_box .my_box>div{
    margin-bottom: 8px;
    padding: 18px 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
.coupon_box .my_box .right{
    flex: 1;
    margin-left: 16px;
    text-align: right
}
.coupon_box .my_box .right .number span{
    display: inline-block;
    margin-right: 2px;
    vertical-align: top;
}
.coupon_box .my_box .right .coupon_button{
    margin-top: 8px
}
.coupon_box .my_box .right .coupon_button .button{
    border: 0 none;
    font-size: 0
}
.coupon_box .my_box .right .coupon_button .button span{
    vertical-align: middle;
}
.coupon_box .my_box .right .coupon_button .button span b{
    font-weight: bold ;
}
.coupon_box .my_box .right .coupon_button .button::after{
    display: inline-block;
    content: '';
    width: 1rem;
    height: 1rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url(#{base.$images-base}/mypage/icon_info_circle.svg);
    vertical-align: middle;
    margin-left: 4px
}
.coupon_box .list_box>li:last-child{
    border-bottom: 0 none
}

/* 댓글, 답글 */
.section .comment_list.list{
    margin-top: 0
}




.history_list .tit_con .right .button.infoBtn span{
    width: 1rem;
    height: 1rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 3px;
    background-image: url(#{base.$images-base}/details/icon_info_circle_LightMode.svg);
}


/* 공지사항 */
.notice_list .info_txt{
    flex: 1
}
.notice_list .info_txt .date{
    margin-top: 6px;
}

.list_more_btn {
    margin: 24px 0;
    text-align: center;
}

.list_more_btn .button span {
    margin: 0 3px;
}

.list_more_btn .button::after{
    display: inline-block;
    content: '';
    vertical-align: top;
    width: 1rem;
    height: 1rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(#{base.$images-base}/icon_list_more_btn_gray-LightMode.svg);
}

.section .select_btn.info span {
    margin-right: 0px;
}

.section .select_btn.info span:last-child::before {
    display: inline-block;
    content: "·";
    color: rgb(169, 165, 165);
    font-weight: 400;
    margin: 0px 3px;
}

.notice_list .txt_box .desc b {
    font-weight: bold;
}

@media (max-width: 999px) {

    .section{
        padding: 40px 0;
    }
    .section{
        // margin-top: 36px;
        padding-left:12px;
        padding-right: 12px
    }
    .section .tit_con,
    .section .list{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .my_profile.section{
        padding: 40px 12;
    }
    .my_page .my_pay .coupon_box{
        height: 168px;
    }
    .my_page .my_pay .coupon_box>.left{
        flex-direction: column;
    }
    .my_page .my_pay .coupon_box>.left .icon_coupon_box{
        margin: 0;
        width: 40px;
        height: 40px;
        margin-bottom: 16px
    }
    .my_page .my_pay .coupon_box .num_btn{
        margin-top: 4px
    }
    .my_page .my_pay .coupon_box>.right{
        width: 240px;
        flex-direction: column;
        padding: 0 16px;
        box-sizing: border-box;
    }
    .my_page .my_pay .coupon_box>.right .after{
        flex-direction: column;
    }
    .my_page .my_pay .coupon_box>.right .after>div{
        margin: 0;
        padding: 8px 0
    }
    .myEmblem .emblem_list{
        padding-right: 12px;
        margin-top: 8px
    }
    .myEmblem .emblem_list ul{
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .comment_list{
        margin-bottom: 4rem
    }

    .comment_list .comment_box .top_con{
        padding: 0;
        margin: 0;
    }

}
@media (max-width: 540px) {
    // .my_profile.section{
    //     padding: 0px 12px 40px;
    // }
    .my_page .my_profile .my_info .profile{
        width: 96px;
        height: 96px;
    }
    .my_page .my_profile .my_info .info_txt{
        margin-left: 12px;
    }
    .my_page .my_profile .my_info .info_txt .rating_box .left{
        margin-top: 8px;
    }
    .my_page .menu_list nav>ul{
        grid-template-columns: none
    }
    .my_page .my_pay .coupon_box>.right{
        width: 140px;
        flex-direction: column;
        padding: 0 16px;
        box-sizing: border-box;
    }
    .myEmblem .emblem_list ul{
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .myBenefit .my_benefit .my_grade{
        padding: 20px 0
    }
    .connection_box .signup_list>li{
        height: 72px
    }
    .history_list .list,
    .history_list .tit_con,
    .history_list ul{
        margin-top: 0
    }
    
}