
.containerr .genre_banner .list li{
    margin-top: 12px
}
.containerr .genre_banner .list li a{
    position: relative;
    padding-top: 24px;
    box-sizing: border-box;
}
.containerr .genre_banner .cover_img{
    top: 0;
    margin: 0;
}
.containerr .genre_banner .cover_img::after{
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 130px;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 30.3%, rgba(0, 0, 0, 0.55) 100%);
    border-radius: 0 0 8px 8px;
}
.containerr .genre_banner .content_box{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 999px) {
    .subDepth.type_header .swiper-slide{
        margin-right: 8px !important;
    }
    .subDepth.type_header .swiper-slide:last-child{
        margin-right: 0px !important;
    }
}

@media (max-width: 540px) {
    
    .containerr .genre_banner .list li:first-child{
        margin-top: 0
    }
    .containerr .genre_banner .cover_img::after{
        height: 100px
    }
    .containerr .genre_banner .content_box{
        width: 100%;
        text-align: left;
        left: 0;
        transform: none;
        padding: 16px 12px;
        box-sizing: border-box;
    }

}