.containerr .list li a{
    align-items: flex-start;
    padding: 16px 0;
    display: flex;
}
.containerr .list li .icon_box span{
    display: block;
    width: 64px;
    height: 64px;
    overflow: hidden;
}
.containerr .list li .icon_box span img{ 
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.containerr .list li .text_box{
    flex: 1;
    margin-left: 12px
}
.containerr .list li .text_box>p:not(:first-of-type){
    margin-top: 8px
}
.containerr .list li .text_box .title{
    display: inline-block;
    position: relative
}

@media (max-width: 540px) {

    .containerr .list li .icon_box span{
        width: 48px;
        height: 48px
    }
    .containerr .list li .text_box{
        margin-left: 8px
    }
    .section .banner_big.list{
        margin-top: 0
    }
}