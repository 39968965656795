@use "style/base/base.scss" as base;

.search_btn {
    span{
        display: inline-block;
        margin-right: 3px;
        vertical-align: top;
    }
    span::before{
        display: inline-block;
        content: '';
        width: 1rem;
        height: 1rem;
        vertical-align: top;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(#{base.$images-base}/storage/list_search.svg);
        margin-right: 3px;
    }
}


.template {
    .info_txt {
        .date{
            margin-top: 16px
        }
    }
}
.temp_list {
    &.storage_list {
        .list {
            li{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            a{
                flex: 1 1 0;
            }
            .button_box{
                display: block;
                text-align: center;
                p{
                    margin-top: 6px;
                }
            }
        }
    }
}

.section {
    .top_box{
        margin-bottom: 8px
    }
    .after{
        display: none;
    }
}
.edit_select{
    margin: 0 12px;
    display: none
}
.input_box{
    align-items: center;
    justify-content: center;
    .text_input{
        flex: 1 1 0;
    }
    .button{
        margin-left: 12px;
        border: 0 none;
        span{
            margin: 0 3px;
        }
    }
    .text_input {
        &::before{
            display: inline-block;
            content: '';
            width: 1rem;
            height: 1rem;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url(#{base.$images-base}/storage/icon_list_search-LightMode.svg);
            margin-left: 12px;
        }
        input{
            padding: .5rem  .75rem;
            padding-left: 4px;
        }
        .right_btns{
            margin-right: 12px;
            span{
                width: 1rem;
                height: 1rem;
            }
        }
        
    }
}

.button {
    &.bell_icon{
        border: 0 none;
        background-position: center;
        background-repeat: no-repeat;
        width: 48px;
        height: 48px;
        background-size: 24px;
        background-image: url(#{base.$images-base}/storage/icon_alarm.svg);
        &.click{
            background-image: url(#{base.$images-base}/storage/icon_alarm_click.svg);
        }
    }
}

.fixed_button{
    display: none;
}


@media (max-width: 999px) {

    .header_inner .logo a{
        display: none;
    }
    .header_inner .logo span{
        display: block;
    }
    .input_box{
        padding: 0 12px;
    } 

    .section .tit_con,
    .section .top_con,
    .section.banner_big,
    .genre_banner .list,
    .section.temp_list .list,
    .section.history_list .list{
      padding-left: 12px;
      padding-right: 12px;
      box-sizing: border-box;
    }

    .temp_list.template_column .list ul{
        display: block;
        top: 0;
      }

}