
.modal-body {
    overflow: hidden !important;
    overflow-y: auto !important;
    padding: 40px 12px !important;
    max-width: 540px !important;
    margin: 0 auto !important;
}

.modal-dialog {
    max-width: 360px !important;
}
.modal-body > div:not(:first-of-type) {
    margin-top: 24px;
}
.title_box {
    text-align: center;
}
.title_box .data {
    margin-top: 12px;
}
.coupon_box {
    text-align: center;
}
.coupon_box .table_box .title {
    padding: 12px 10px;
    border-radius: 8px 8px 0 0;
}
.coupon_box .table_box .number {
    border-width: 1px;
    border-style: solid;
    border-top-width: 0;
    padding: 20px 10px;
    border-radius: 0 0 8px 8px;
}
.coupon_box .info_txt {
    margin-top: 8px;
}
.botton_box button {
    width: 100%;

}


@media (max-width: 999px) {
    .modal-dialog {
        max-width: 100% !important;
    }
    .modal-dialog.large .modal-content {
        padding: 0 !important;
    }
    .modal-dialog-centered.large .modal-body {
        overflow: hidden !important;
        overflow-y: auto !important;
        padding: 40px 12px !important;
        max-width: 540px !important;
        margin: 0 auto !important;
    }

}